    /*
    Flaticon icon font: Flaticon
    Creation date: 22/06/2016 15:07
    */

    @font-face {
  font-family: "Flaticon";
  src: url("../../../../fonts/Flaticon.eot");
  src: url("../../../../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../../../../fonts/Flaticon.woff") format("woff"),
       url("../../../../fonts/Flaticon.ttf") format("truetype"),
       url("../../../../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../../../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-24-hours-delivery:before { content: "\f100"; }
.flaticon-24-hours-phone-service:before { content: "\f101"; }
.flaticon-24-hours-symbol:before { content: "\f102"; }
.flaticon-air-transport:before { content: "\f103"; }
.flaticon-airplane-around-earth:before { content: "\f104"; }
.flaticon-airplane-frontal-view:before { content: "\f105"; }
.flaticon-airplane-in-vertical-ascending-position:before { content: "\f106"; }
.flaticon-barscode-with-a-magnifier-business-symbol:before { content: "\f107"; }
.flaticon-black-delivery-small-truck-side-view:before { content: "\f108"; }
.flaticon-black-opened-umbrella-symbol-with-rain-drops-falling-on-it:before { content: "\f109"; }
.flaticon-boat-from-front-view:before { content: "\f10a"; }
.flaticon-box-of-packing-for-delivery:before { content: "\f10b"; }
.flaticon-boxes-piles-stored-inside-a-garage-for-delivery:before { content: "\f10c"; }
.flaticon-boxes-storage-for-delivery-inside-a-truck-box-from-back-view:before { content: "\f10d"; }
.flaticon-call-center-service-for-information:before { content: "\f10e"; }
.flaticon-call-center-worker-with-headset:before { content: "\f10f"; }
.flaticon-chronometer:before { content: "\f110"; }
.flaticon-clipboard-verification-symbol:before { content: "\f111"; }
.flaticon-commercial-delivery-symbol-of-a-list-on-clipboard-on-a-box-package:before { content: "\f112"; }
.flaticon-container-hanging-of-a-crane:before { content: "\f113"; }
.flaticon-container-on-a-crane:before { content: "\f114"; }
.flaticon-containers-on-oceanic-ship:before { content: "\f115"; }
.flaticon-crane:before { content: "\f116"; }
.flaticon-crane-truck:before { content: "\f117"; }
.flaticon-delivered-box-verification-symbol:before { content: "\f118"; }
.flaticon-delivery-box:before { content: "\f119"; }
.flaticon-delivery-box-and-timer:before { content: "\f11a"; }
.flaticon-delivery-box-on-a-hand:before { content: "\f11b"; }
.flaticon-delivery-box-package-opened-with-up-arrow:before { content: "\f11c"; }
.flaticon-delivery-cube-box-package-with-four-arrows-in-different-directions:before { content: "\f11d"; }
.flaticon-delivery-of-a-box:before { content: "\f11e"; }
.flaticon-delivery-pack-security-symbol-with-a-shield:before { content: "\f11f"; }
.flaticon-delivery-package:before { content: "\f120"; }
.flaticon-delivery-package-box-with-fragile-content-symbol-of-broken-glass:before { content: "\f121"; }
.flaticon-delivery-package-opened:before { content: "\f122"; }
.flaticon-delivery-package-with-umbrella-symbol:before { content: "\f123"; }
.flaticon-delivery-packages-on-a-trolley:before { content: "\f124"; }
.flaticon-delivery-packaging-box:before { content: "\f125"; }
.flaticon-delivery-packing:before { content: "\f126"; }
.flaticon-delivery-scale-with-a-box:before { content: "\f127"; }
.flaticon-delivery-time-symbol:before { content: "\f128"; }
.flaticon-delivery-time-tool:before { content: "\f129"; }
.flaticon-delivery-transportation-machine:before { content: "\f12a"; }
.flaticon-delivery-truck:before { content: "\f12b"; }
.flaticon-delivery-truck-1:before { content: "\f12c"; }
.flaticon-delivery-truck-2:before { content: "\f12d"; }
.flaticon-delivery-truck-with-circular-clock:before { content: "\f12e"; }
.flaticon-delivery-truck-with-packages-behind:before { content: "\f12f"; }
.flaticon-delivery-worker-giving-a-box-to-a-receiver:before { content: "\f130"; }
.flaticon-fragile-broken-glass-symbol-for-delivery-boxes:before { content: "\f131"; }
.flaticon-free-delivery-truck:before { content: "\f132"; }
.flaticon-frontal-truck:before { content: "\f133"; }
.flaticon-identification-for-delivery-with-bars:before { content: "\f134"; }
.flaticon-international-calling-service-symbol:before { content: "\f135"; }
.flaticon-international-delivery:before { content: "\f136"; }
.flaticon-international-delivery-business-symbol-of-world-grid-with-an-arrow-around:before { content: "\f137"; }
.flaticon-international-delivery-symbol:before { content: "\f138"; }
.flaticon-international-logistics-delivery-truck-symbol-with-world-grid-behind:before { content: "\f139"; }
.flaticon-localization-orientation-tool-of-compass-with-cardinal-points:before { content: "\f13a"; }
.flaticon-locked-package:before { content: "\f13b"; }
.flaticon-logistics-delivery-truck-and-clock:before { content: "\f13c"; }
.flaticon-logistics-delivery-truck-in-movement:before { content: "\f13d"; }
.flaticon-logistics-package:before { content: "\f13e"; }
.flaticon-logistics-transport:before { content: "\f13f"; }
.flaticon-logistics-truck:before { content: "\f140"; }
.flaticon-logistics-weight-scale:before { content: "\f141"; }
.flaticon-man-standing-with-delivery-box:before { content: "\f142"; }
.flaticon-ocean-transportation:before { content: "\f143"; }
.flaticon-package-cube-box-for-delivery:before { content: "\f144"; }
.flaticon-package-delivery:before { content: "\f145"; }
.flaticon-package-delivery-in-hand:before { content: "\f146"; }
.flaticon-package-for-delivery:before { content: "\f147"; }
.flaticon-package-on-rolling-transport:before { content: "\f148"; }
.flaticon-package-transport-for-delivery:before { content: "\f149"; }
.flaticon-package-transportation-on-a-trolley:before { content: "\f14a"; }
.flaticon-packages-storage-for-delivery:before { content: "\f14b"; }
.flaticon-packages-transportation-on-a-truck:before { content: "\f14c"; }
.flaticon-person-standing-beside-a-delivery-box:before { content: "\f14d"; }
.flaticon-phone-auricular-and-a-clock:before { content: "\f14e"; }
.flaticon-phone-auricular-and-clock-delivery-symbol:before { content: "\f14f"; }
.flaticon-placeholder-on-map-paper-in-perspective:before { content: "\f150"; }
.flaticon-protection-symbol-of-opened-umbrella-silhouette-under-raindrops:before { content: "\f151"; }
.flaticon-sea-ship:before { content: "\f152"; }
.flaticon-sea-ship-with-containers:before { content: "\f153"; }
.flaticon-search-delivery-service-tool:before { content: "\f154"; }
.flaticon-storage:before { content: "\f155"; }
.flaticon-talking-by-phone-auricular-symbol-with-speech-bubble:before { content: "\f156"; }
.flaticon-telephone:before { content: "\f157"; }
.flaticon-telephone-1:before { content: "\f158"; }
.flaticon-three-stored-boxes-for-delivery:before { content: "\f159"; }
.flaticon-train-front:before { content: "\f15a"; }
.flaticon-triangular-arrows-sign-for-recycle:before { content: "\f15b"; }
.flaticon-up-arrows-couple-sign-for-packaging:before { content: "\f15c"; }
.flaticon-verification-of-delivery-list-clipboard-symbol:before { content: "\f15d"; }
.flaticon-view-symbol-on-delivery-opened-box:before { content: "\f15e"; }
.flaticon-weight-of-delivery-package-on-a-scale:before { content: "\f15f"; }
.flaticon-weight-tool:before { content: "\f160"; }
.flaticon-woman-with-headset:before { content: "\f161"; }
.flaticon-wood-package-box-of-square-shape-for-delivery:before { content: "\f162"; }
.flaticon-world-grid-with-placeholder:before { content: "\f163"; }
    
    $font-Flaticon-24-hours-delivery: "\f100";
    $font-Flaticon-24-hours-phone-service: "\f101";
    $font-Flaticon-24-hours-symbol: "\f102";
    $font-Flaticon-air-transport: "\f103";
    $font-Flaticon-airplane-around-earth: "\f104";
    $font-Flaticon-airplane-frontal-view: "\f105";
    $font-Flaticon-airplane-in-vertical-ascending-position: "\f106";
    $font-Flaticon-barscode-with-a-magnifier-business-symbol: "\f107";
    $font-Flaticon-black-delivery-small-truck-side-view: "\f108";
    $font-Flaticon-black-opened-umbrella-symbol-with-rain-drops-falling-on-it: "\f109";
    $font-Flaticon-boat-from-front-view: "\f10a";
    $font-Flaticon-box-of-packing-for-delivery: "\f10b";
    $font-Flaticon-boxes-piles-stored-inside-a-garage-for-delivery: "\f10c";
    $font-Flaticon-boxes-storage-for-delivery-inside-a-truck-box-from-back-view: "\f10d";
    $font-Flaticon-call-center-service-for-information: "\f10e";
    $font-Flaticon-call-center-worker-with-headset: "\f10f";
    $font-Flaticon-chronometer: "\f110";
    $font-Flaticon-clipboard-verification-symbol: "\f111";
    $font-Flaticon-commercial-delivery-symbol-of-a-list-on-clipboard-on-a-box-package: "\f112";
    $font-Flaticon-container-hanging-of-a-crane: "\f113";
    $font-Flaticon-container-on-a-crane: "\f114";
    $font-Flaticon-containers-on-oceanic-ship: "\f115";
    $font-Flaticon-crane: "\f116";
    $font-Flaticon-crane-truck: "\f117";
    $font-Flaticon-delivered-box-verification-symbol: "\f118";
    $font-Flaticon-delivery-box: "\f119";
    $font-Flaticon-delivery-box-and-timer: "\f11a";
    $font-Flaticon-delivery-box-on-a-hand: "\f11b";
    $font-Flaticon-delivery-box-package-opened-with-up-arrow: "\f11c";
    $font-Flaticon-delivery-cube-box-package-with-four-arrows-in-different-directions: "\f11d";
    $font-Flaticon-delivery-of-a-box: "\f11e";
    $font-Flaticon-delivery-pack-security-symbol-with-a-shield: "\f11f";
    $font-Flaticon-delivery-package: "\f120";
    $font-Flaticon-delivery-package-box-with-fragile-content-symbol-of-broken-glass: "\f121";
    $font-Flaticon-delivery-package-opened: "\f122";
    $font-Flaticon-delivery-package-with-umbrella-symbol: "\f123";
    $font-Flaticon-delivery-packages-on-a-trolley: "\f124";
    $font-Flaticon-delivery-packaging-box: "\f125";
    $font-Flaticon-delivery-packing: "\f126";
    $font-Flaticon-delivery-scale-with-a-box: "\f127";
    $font-Flaticon-delivery-time-symbol: "\f128";
    $font-Flaticon-delivery-time-tool: "\f129";
    $font-Flaticon-delivery-transportation-machine: "\f12a";
    $font-Flaticon-delivery-truck: "\f12b";
    $font-Flaticon-delivery-truck-1: "\f12c";
    $font-Flaticon-delivery-truck-2: "\f12d";
    $font-Flaticon-delivery-truck-with-circular-clock: "\f12e";
    $font-Flaticon-delivery-truck-with-packages-behind: "\f12f";
    $font-Flaticon-delivery-worker-giving-a-box-to-a-receiver: "\f130";
    $font-Flaticon-fragile-broken-glass-symbol-for-delivery-boxes: "\f131";
    $font-Flaticon-free-delivery-truck: "\f132";
    $font-Flaticon-frontal-truck: "\f133";
    $font-Flaticon-identification-for-delivery-with-bars: "\f134";
    $font-Flaticon-international-calling-service-symbol: "\f135";
    $font-Flaticon-international-delivery: "\f136";
    $font-Flaticon-international-delivery-business-symbol-of-world-grid-with-an-arrow-around: "\f137";
    $font-Flaticon-international-delivery-symbol: "\f138";
    $font-Flaticon-international-logistics-delivery-truck-symbol-with-world-grid-behind: "\f139";
    $font-Flaticon-localization-orientation-tool-of-compass-with-cardinal-points: "\f13a";
    $font-Flaticon-locked-package: "\f13b";
    $font-Flaticon-logistics-delivery-truck-and-clock: "\f13c";
    $font-Flaticon-logistics-delivery-truck-in-movement: "\f13d";
    $font-Flaticon-logistics-package: "\f13e";
    $font-Flaticon-logistics-transport: "\f13f";
    $font-Flaticon-logistics-truck: "\f140";
    $font-Flaticon-logistics-weight-scale: "\f141";
    $font-Flaticon-man-standing-with-delivery-box: "\f142";
    $font-Flaticon-ocean-transportation: "\f143";
    $font-Flaticon-package-cube-box-for-delivery: "\f144";
    $font-Flaticon-package-delivery: "\f145";
    $font-Flaticon-package-delivery-in-hand: "\f146";
    $font-Flaticon-package-for-delivery: "\f147";
    $font-Flaticon-package-on-rolling-transport: "\f148";
    $font-Flaticon-package-transport-for-delivery: "\f149";
    $font-Flaticon-package-transportation-on-a-trolley: "\f14a";
    $font-Flaticon-packages-storage-for-delivery: "\f14b";
    $font-Flaticon-packages-transportation-on-a-truck: "\f14c";
    $font-Flaticon-person-standing-beside-a-delivery-box: "\f14d";
    $font-Flaticon-phone-auricular-and-a-clock: "\f14e";
    $font-Flaticon-phone-auricular-and-clock-delivery-symbol: "\f14f";
    $font-Flaticon-placeholder-on-map-paper-in-perspective: "\f150";
    $font-Flaticon-protection-symbol-of-opened-umbrella-silhouette-under-raindrops: "\f151";
    $font-Flaticon-sea-ship: "\f152";
    $font-Flaticon-sea-ship-with-containers: "\f153";
    $font-Flaticon-search-delivery-service-tool: "\f154";
    $font-Flaticon-storage: "\f155";
    $font-Flaticon-talking-by-phone-auricular-symbol-with-speech-bubble: "\f156";
    $font-Flaticon-telephone: "\f157";
    $font-Flaticon-telephone-1: "\f158";
    $font-Flaticon-three-stored-boxes-for-delivery: "\f159";
    $font-Flaticon-train-front: "\f15a";
    $font-Flaticon-triangular-arrows-sign-for-recycle: "\f15b";
    $font-Flaticon-up-arrows-couple-sign-for-packaging: "\f15c";
    $font-Flaticon-verification-of-delivery-list-clipboard-symbol: "\f15d";
    $font-Flaticon-view-symbol-on-delivery-opened-box: "\f15e";
    $font-Flaticon-weight-of-delivery-package-on-a-scale: "\f15f";
    $font-Flaticon-weight-tool: "\f160";
    $font-Flaticon-woman-with-headset: "\f161";
    $font-Flaticon-wood-package-box-of-square-shape-for-delivery: "\f162";
    $font-Flaticon-world-grid-with-placeholder: "\f163";