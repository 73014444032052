
// dropdown
.dropdown-toggle {
    &:after {
        display: none;        
    }
}

.shipment-card {
	color: #fff;
    h1,h2,h3,h4,h5,h6 {
        color: #fff;
    }
    a {
        color:#fff;
    }
	.card {
		border-radius: 5px;
		-webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
		box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
		border: none;
		margin-bottom: 10px;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		.card-block {
			padding: 15px;
		}
	}
	i {
		font-size: 26px;
	}
}


.shipment-list-table{
    border-collapse:separate; 
    border-spacing:0 12px; 
  tr{
    background-color: $card-bg;
  }
  
}

.bg-c-cloud {
	background: linear-gradient(45deg,#6ca7eb,#32547a);
}

.bg-c-blue {
	background: linear-gradient(45deg,#4099ff,#739dce);
}
.bg-c-green {
	background: linear-gradient(45deg,#0fa00f,#32ca46);
}
.bg-c-pink {
	background: linear-gradient(45deg,#FF5370,#ff869a);
}
.bg-c-red {
	background: linear-gradient(45deg,#f10b31,#aa2239);
}

.bg-c-purple {
	background: linear-gradient(45deg,#5c08bd,#a94ce7);
}

.bg-c-yellow {
	background: linear-gradient(45deg,#99970f,#696812);
}


.f-left {
	float: left;
}
.f-right {
	float: right;
}

.bg-incoming-wh{
	background:#f10b31;
}
.bg-manifest{
	background:#99970f;
}
.bg-departure{
	background:#6ca7eb;
}

.bg-arrival{
	background:#4099ff;
}

.bg-delivery{
	background: #5c08bd;
}
.bg-received{
	background: #0fa00f;
}


 

.avatar-sm{
    height: auto;
    width: 3em;
}